.wraper {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    justify-content: center;
    align-items: center;
    height: 100vh;

    @media (min-width: 768px) {
        padding: 0 100px;
    }

    @media (min-width: 1024px) {
        padding: 0 200px;
    }

    @media (min-width: 1440px) {
        padding: 0 472px;
    }

    p {
        margin-top: 5px;
    }
}
.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
}
.title {
    text-align: left;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 30px;
    @media (max-width: 768px) {
        font-size: 24px;
    }

    @media (max-width: 480px) {
        font-size: 18px;
    }
}
.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    /* Optional: Set a max-width for the logo container */
    max-width: 100%;
    box-sizing: border-box;
    img {
        max-width: 100%;
        height: auto;
        /* Optional: Add additional styling for the image */
        object-fit: contain;
    }
}
.content {
    flex: 8;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.input {
    margin-bottom: 20px;
}
.forgot {
    font-weight: 700;
    margin-top: 20px;
    cursor: pointer;
}
.alert {
    color: rgb(220, 53, 69);
    font-size: 14px;
    margin-top: -10px;
    margin-bottom: 15px;
    margin-left: 10px;
    text-align: left;
    width: 100%;
}
