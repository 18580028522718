.wraper {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    justify-content: center;
    align-items: center;
    height: 100vh;

    @media (min-width: 768px) {
        padding: 0 100px;
    }

    @media (min-width: 1024px) {
        padding: 0 200px;
    }

    @media (min-width: 1440px) {
        padding: 0 450px;
    }

    p {
        margin-top: 5px;
    }
}
.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    width: 100%;
}
.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    /* Optional: Set a max-width for the logo container */
    max-width: 100%;
    box-sizing: border-box;
    img {
        max-width: 100%;
        height: auto;
        /* Optional: Add additional styling for the image */
        object-fit: contain;
    }
}
.content {
    flex: 8;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.input {
    margin-bottom: 20px;
}
.forgot {
    font-weight: 700;
    margin-top: 20px;
    cursor: pointer;
    a {
        text-decoration: none;
        color: black;
    }
}
.alert {
    color: rgb(220, 53, 69);
    font-size: 14px;
    margin-top: -10px;
    margin-bottom: 15px;
    margin-left: 10px;
    text-align: left;
    width: 100%;
}
