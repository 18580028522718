input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    display: none;
}
.wraper {
    position: relative;
    width: 100%;
    display: flex;
}
.input {
    width: 100%;
    padding: 15px 15px;
    font-size: 14px;
    border: 1px solid rgb(208, 208, 208);
    border-radius: 10px;
}

.iconRight {
    position: absolute;
    right: 15px;
    top: 12px;
    cursor: pointer;
}
