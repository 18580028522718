.wraper {
    padding-bottom: 10px;
    p {
        line-height: 1.3;
        margin-bottom: 23px;
    }
    span {
        color: #447dfd;
    }
}
.topic {
    color: #292d31d4;
}
.more {
    margin-top: -20px;
}
