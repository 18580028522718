.wraper {
    padding: 0 20px;
    height: 100vh;

    @media (min-width: 768px) {
        padding: 0 50px;
    }

    @media (min-width: 1024px) {
        padding: 0 100px;
    }

    @media (min-width: 1440px) {
        padding: 0 228px;
    }
}

.container {
    display: flex;
    flex-direction: row;
}
.menu-bar {
    width: 33%;
    padding-top: 45px;
    padding-right: 32px;
    border-right-width: 1px;
    border-right-style: solid;
    border-color: #dfe5eb;
    h2 {
        margin: 15px 0;
    }
    @media (max-width: 675px) {
        display: none;
    }
}
.menu-mobile {
    display: none;
    padding: 10px 0;
    display: none;
    width: 100%;
    justify-content: space-between;
    background-color: #ebf6f9;
    @media (max-width: 675px) {
        display: flex;
        position: fixed;
        .content {
            margin-top: 60px !important;
        }
    }
    .logo {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 15px;
    }
}
.button-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2px;
}
.content {
    width: 67%;
    padding-top: 45px;
    padding-left: 32px;
    p {
        margin: 20px 0;
        margin-bottom: 25px;
    }
    .ticket {
        color: #464c52cc;
    }
    @media (max-width: 675px) {
        width: 100%;
        padding-left: 0;
        margin-top: 12px;
    }
}
.suspend {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}
.appeal {
    li {
        margin: 15px 0;
        color: #3e4348;
        line-height: 1.3;
    }
}
.card {
    overflow: hidden;
    border-radius: 15px;
    margin-top: 20px;
    background-color: white;
    img {
        width: 100%;
    }
}
.card-content {
    padding: 20px;
    .card-type {
        margin-top: 0;
        margin-bottom: 15px;
        font-size: 16px;
    }
    h2 {
        font-weight: 300;
    }
}
.privacy-center,
.more-detail,
.additional {
    color: #3e4348;
    font-weight: 300;
}

.continually {
    padding-top: 10px;
    padding-bottom: 15px;
    span {
        color: #447dfd;
    }
}
.settingItemBtn {
    span {
        margin-left: 35px;
        text-align: left;
    }
}
